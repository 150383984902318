import React from 'react';

// MUI
import { Box, Container, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// UI
import {
  SectionHeadingText,
  ParagraphText,
  HeadSectionImg,
  PrimaryButton,
  SecondaryButton,
} from '../../../ui';

// Images from Public Dir
import HeaderImg from '../../../../public/header/meditag_doctor.svg';
import CountImg from '../../../../public/header/count.svg';

import { useRouter } from 'next/router';

const Header = () => {

  return (
    <Box component="header">
      <Container maxWidth="xl" sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={7}>
            <ContentSection />
          </Grid>
          <Grid item xs={12} md={5}>
            <HeadSectionImg src={HeaderImg} alt="Meditagg" width={400} height={400} priority />
          </Grid>
          <Grid item md={12}>
            <CountImgContainer />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl" sx={{ display: { xs: 'block', md: 'none' } }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <HeadSectionImg src={HeaderImg} alt="Meditagg" width={400} height={400} priority />
          </Grid>
          <Grid item xs={12}>
            <ContentSection />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const ContentSection = () => {
  return (
    <>
      <SectionHeadingText style={{ mt: 2, fontSize: "40px", color: 'var(--green-color)', fontWeight: '600' }}>
        India's
      </SectionHeadingText>
      <SectionHeadingText style={{ fontSize: '40px', fontWeight: '600', padding: '0 70px 0 0' }}>
        Top Clinic Management Software For Your Practice!
      </SectionHeadingText>
      <ParagraphText sx={{ mt: 2, fontSize: "15px", padding: '0 70px 0 0', color: 'var(--banner-color)' }}>
        Meditagg: Bringing the best hospital software in India to your fingertips, enhancing patient care and operational efficiency.
      </ParagraphText>
      <ButtonSection />
      <ParagraphText sx={{ mt: 2, fontSize: "12px", color: 'var(--banner-color)', fontSize: '15px' }}>
        No Credit cards required, Full access for 14 Days, Free Support
      </ParagraphText>
    </>
  );
};

const ButtonSection = () => {
  const router = useRouter();

  const redirectToContactPage = () => {
    router.push('/#contact');
  };
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <PrimaryButton onClick={redirectToContactPage} style={{ borderRadius: "30px", fontFamily: "inter", fontSize: '15px', width: "180px", height: '40px', marginTop: '10px' }}>Try For Free <ArrowForwardIcon /></PrimaryButton>
        <SecondaryButton onClick={redirectToContactPage} style={{ borderRadius: "30px", fontFamily: "inter", fontSize: '15px', width: "180px", height: '40px', marginTop: '10px' }}>Book a Demo <ArrowForwardIcon /></SecondaryButton>
      </Box>
    </>
  );
};

const CountImgContainer = () => {
  return (
    <HeadSectionImg
      src={CountImg}
      alt="Meditagg"
      width={1000}
      height={400}
      priority
      style={{ width: "1000px", marginLeft: '-20px', marginTop: '-170px' }}
    />
  );
};

export default Header;
