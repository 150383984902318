import React, { Suspense } from 'react'

// Nextjs
import Head from 'next/head'
import dynamic from 'next/dynamic'

// MUI
import { Box, Container } from '@mui/material'

// Home Page Component
import Header from '../components/Index_Page/Header'

const MeditaggFeatures = dynamic(
  () => import('../components/Index_Page/Features'),
  {
    ssr: false,
  },
)
const FAQComponent = dynamic(() => import('../components/Index_Page/FAQ'), {
  ssr: false,
})
const ContactUs = dynamic(() => import('../components/Index_Page/Contact-Us'), {
  ssr: false,
})

const Testimonial = dynamic(() => import('../components/Index_Page/Testimonials'), {
  ssr: false,
})

const About = dynamic(() => import('../components/Index_Page/About'), {
  ssr: false,
})

const BlogComponent = dynamic(() => import('../components/Index_Page/Blog'), {
  ssr: false,
})

const IframeComponent = dynamic(() => import('../components/Index_Page/Iframe'), {
  ssr: false,
})

const ContactFormComponent = dynamic(() => import('../layout/form/contactForm'),{
  ssr: false,
})

const Home = () => {
  return (
    <Box component="div">
      <Head>
        <title>Welcome to Meditagg</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 5,
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 5 }}>
            <Header />

            <Suspense fallback="Loading...">
              <About />
            </Suspense>

            <Suspense fallback="Loading...">
              <MeditaggFeatures />
            </Suspense>

            <Suspense fallback="Loading...">
              <ContactUs />
            </Suspense>

            <Suspense fallback="Loading...">
              <Testimonial />
            </Suspense>

            <Suspense fallback="Loading...">
              <FAQComponent />
            </Suspense>

            <Suspense fallback="Loading...">
              <BlogComponent />
            </Suspense>

            <Suspense fallback="Loading...">
              <IframeComponent />
            </Suspense>

            <Suspense fallback="Loading...">
              <ContactFormComponent/>
            </Suspense>

          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default Home
